import React, { useState } from 'react';
import { BiSolidPaperPlane } from "react-icons/bi";
import { RxArrowTopRight } from "react-icons/rx";
import { Element, scroller } from 'react-scroll';
import { Link } from "react-router-dom";
import { useInView } from 'react-intersection-observer';
import './Home.scss';
import './About.scss';
import highlightExpert from '../assets/images/content/highlight-expert.svg';
import storyImage from '../assets/images/content/a0.png';
import Contact from "../components/Contact";
import Footer from "../components/Footer";

function About() {
    const sections = [
        {
            id: "01.",
            title: "Engagement",
            text: "We leverage our industry insights to deliver proactive, impactful advice that keeps you ahead of trends."
        },
        {
            id: "02.",
            title: "Collaboration",
            text: "We foster partnerships with clear communication, creating solutions aligned with your goals at every stage."
        },
        {
            id: "03.",
            title: "Agility",
            text: "We adapt swiftly to industry shifts, providing flexible, responsive strategies that meet evolving needs."
        }
    ];

    const [hoveredSection, setHoveredSection] = useState(sections[0]);

    // Define refs and inView states for each section
    const { ref: heroRef, inView: heroInView } = useInView({ triggerOnce: true, threshold: 0.1 });
    const { ref: valuesRef, inView: valuesInView } = useInView({ triggerOnce: true, threshold: 0.1 });
    const { ref: storyRef, inView: storyInView } = useInView({ triggerOnce: true, threshold: 0.1 });

    const scrollToBannerSection = () => {
        scroller.scrollTo('home-about', {
            duration: 600,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -70,
        });
    };

    return (
        <div>
            {/* Hero Section with fade-in animation */}
            <div ref={heroRef} className={`home-hero ak-primary-bg fade-in-section ${heroInView ? 'animate-fade-up' : ''}`}>
                <h2 className="semi-bold f-15 ak-white home-welcome-h2">
                    ABOUT US
                </h2>
                <div className="highlight-wrapper">
                    <img
                        src={highlightExpert}
                        alt="Highlight"
                        className="highlight-honest-image"
                    />
                    <h1 className="semi-bold f-55 ak-white hero-title-h1 highlight-honest-text">
                        What Sets
                    </h1>
                </div>
                <h1 className="semi-bold f-55 ak-white hero-title-h1 highlight-honest-text">Us Apart</h1>

                <div className="scroll-downs" onClick={scrollToBannerSection}>
                    <div className="mousey">
                        <div className="scroller"></div>
                    </div>
                </div>
            </div>

            {/* Values Section with fade-in animation */}
            <div ref={valuesRef} className={`home-contact fade-in-section ${valuesInView ? 'animate-fade-up' : ''}`}>
                <Element name={"home-about"}>
                    <h2 className="semi-bold f-15 ak-blue home-welcome-h2">
                        OUR FOUNDATIONAL VALUES
                    </h2>
                    <h1 className="semi-bold f-35 ak-primary hero-title-h1 highlight-expert-text">
                        What You Can Expect
                    </h1>
                    <div className="home-services-card mx-auto">
                        <div className="row mt-5">
                            <div className="col-md-8 col-sm-8">
                                <div className="values-list">
                                    {sections.map(section => (
                                        <div
                                            key={section.id}
                                            className="value-item"
                                            onMouseEnter={() => setHoveredSection(section)}
                                        >
                                            <span className="section-id">{section.id}</span>
                                            <span className="value-title">{section.title}</span>
                                            <RxArrowTopRight size={30} className="values-icon-arrow" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <div className="value-description">
                                    {hoveredSection ? hoveredSection.text : "Hover over a section to see more details."}
                                </div>
                                <Link to={"/contact"} className="ak-round-btn" style={{ maxWidth: 170 }}>
                                    Get in Touch <BiSolidPaperPlane />
                                </Link>
                            </div>
                        </div>
                    </div>
                </Element>
            </div>

            {/* Story Section with fade-in animation */}
            <div ref={storyRef} className={`home-about fade-in-section ${storyInView ? 'animate-fade-up' : ''}`}>
                <Element name="home-story">
                    <div className="parent-container">
                        <div className="story-image-container shadow">
                            <img src={storyImage} alt="Story" />
                        </div>
                    </div>
                    <div className="founder-quote">
                        <h2 className="semi-bold f-15 ak-blue home-welcome-h2">
                            WESLEY WONG
                        </h2>
                        <p className="founder-title">Founder</p>
                        <h1 className="semi-bold f-35 ak-primary hero-title-h1 highlight-expert-text">
                            “Turning Property Goals into Reality,<br />
                            and Immigration Dreams into Action”
                        </h1>
                    </div>
                    <div className="home-services-card mx-auto">
                        <div className="row">
                            <div className="col-sm-12 col-md-5 mb-4">
                                <h2 className="semi-bold f-15 ak-blue text-start">
                                    OUR STORY
                                </h2>
                                <h1 className="semi-bold f-35 ak-primary hero-title-h1 highlight-expert-text text-start">
                                    Impactful<br />Legal<br />Advocates
                                </h1>
                            </div>
                            <div className="col-sm-12 col-md-7 text-start">
                                <p>
                                    At Arkham Law, we believe navigating legal challenges should be seamless. Our mission is to break down the barriers that often hold businesses back, empowering our clients to move forward confidently.
                                </p>
                                <p>
                                    As a comprehensive resource for immigration, real estate, and commercial law, Arkham Law offers strategic support tailored to each client’s needs. Our boutique firm’s integrated approach allows us to scale alongside our clients through every phase of growth, delivering personalized, hands-on service that larger firms often lack.
                                </p>
                                <p>
                                    With expertise in both public and private sectors, we understand the full scope of compliance and regulatory requirements. From small businesses to multinational corporations, our diverse clientele trusts Arkham Law to help them thrive within the bounds of the law.
                                </p>
                            </div>
                        </div>
                    </div>
                </Element>
            </div>

            <Footer />
        </div>
    );
}

export default About;
