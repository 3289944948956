import React from 'react';
import "./Footer.scss"
import {BiSolidPaperPlane} from "react-icons/bi";
import {Link} from "react-router-dom";
import highlightExpert from "../assets/images/content/highlight-expert.svg";
import logoStacked from "../assets/images/logos/logo_stacked_white.svg"
import logoSvg from "../assets/images/logos/logo_only_secondary.svg"
import {HiMiniArrowSmallRight} from "react-icons/hi2";
const FooterMinimal = () => {

    // 111 28 Ave NW, Calgary, AB T2M 2K3
    const position = [51.077620, -114.063350];

    return (
        <div className={"footer-container"}>
            <img alt={"Footer Logo"} src={logoSvg} className={"footer-svg"}/>
            <div className="footer-card container-fluid">

                
                <div className="row">
                    <div className="col-12 col-md-6 text-start mb-4 mb-md-0">
                        <div className="footer-logo-stacked-container">
                            <img src={logoStacked} alt="LogoStacked" className="footer-logo-stacked"/>
                            <p className="mt-3">
                                Your trusted Calgary firm for real estate, immigration, and commercial law services.
                            </p>
                        </div>
                    </div>

                    <div className="col-12 col-md-3 text-start mb-4 mb-md-0">
                        <h4 className="footer-label">Services</h4>
                        <ul className="list-unstyled">
                            <li>
                                <Link to="/services#immigration" className="footer-link">
                                    Immigration Law <HiMiniArrowSmallRight className="link-icon"/>
                                </Link>
                            </li>
                            <li>
                                <Link to="/services#residential" className="footer-link">
                                    Real Estate Law <HiMiniArrowSmallRight className="link-icon"/>
                                </Link>
                            </li>
                            <li>
                                <Link to="/services#commercial" className="footer-link">
                                    Commercial Law <HiMiniArrowSmallRight className="link-icon"/>
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col-12 col-md-3 text-start">
                        <h4 className="footer-label">Support</h4>
                        <ul className="list-unstyled">
                            <li>
                                <Link to="/about" className="footer-link">
                                    About Us <HiMiniArrowSmallRight className="link-icon"/>
                                </Link>
                            </li>
                            {/*<li>*/}
                            {/*    <Link to="/services" className="footer-link">*/}
                            {/*        FAQs <HiMiniArrowSmallRight className="link-icon"/>*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <Link to="/privacy-policy" className="footer-link">*/}
                            {/*        Privacy Policy <HiMiniArrowSmallRight className="link-icon"/>*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <Link to="/terms" className="footer-link">*/}
                            {/*        Terms & Conditions <HiMiniArrowSmallRight className="link-icon"/>*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            <li>
                                <Link to="/contact" className="footer-link">
                                    Contact Us <HiMiniArrowSmallRight className="link-icon"/>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="footer-border"></div>
            <div className="copyright">
                © 2024 Arkham Law
            </div>
        </div>
    );
};

export default FooterMinimal;
