import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LuChevronUp, LuChevronDown } from "react-icons/lu";
import "../pages/Services.scss"

const ServiceCommitment = ({ id, name, description, fullDescription, image }) => {
    const [expanded, setExpanded] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
    }, [expanded, fullDescription]);

    return (
        <div className="card mb-4">
            <div className="card-body shadow-sm">
                <div className="row">
                    <div className="col-12 order-1 order-md-0 d-flex justify-content-start mb-2">
                        <span className="ak-blue f-25" style={{ fontWeight: 700 }}>{id}</span>
                    </div>

                    <div className="col-12 col-md-4 order-2 order-md-1 mb-3 mb-md-0">
                        <img
                            src={image}
                            alt={name}
                            className="img-fluid rounded service-img"
                        />
                    </div>

                    <div className="col-12 col-md-8 order-3 order-md-2 text-start">
                        <h5 className="card-title ak-blue f-25 semi-bold">{name}</h5>

                        <div
                            className="card-text-wrapper"
                            style={{
                                height: expanded ? `${contentHeight}px` : '50px',
                                overflow: 'hidden',
                                transition: 'height 0.3s ease',
                            }}
                        >
                            <p ref={contentRef} className="card-text">
                                {expanded ? fullDescription : description}
                            </p>
                        </div>

                        <button onClick={handleToggle} className="ak-round-btn" style={{ minWidth: 160 }}>
                            {expanded ? 'Show Less' : 'Learn More'}
                            {expanded ? <LuChevronUp /> : <LuChevronDown />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

ServiceCommitment.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    fullDescription: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
};

export default ServiceCommitment;
