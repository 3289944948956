import React from 'react';
import "./ServiceCard.scss"
import { RxArrowTopRight } from "react-icons/rx";
import {Link} from "react-router-dom";

const ServiceCard = ({icon, title, description, serviceId}) => {
    return (
        <Link to={`/services#${serviceId}`} className="service-card-link">
            <div
                className="service-card p-3 text-white"
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#0344dc')}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#00235d')}
            >
                <div className="icon-title-container">
                    <RxArrowTopRight size={30} className="icon-arrow"/>
                    <div className="icon-container">{icon}</div>
                    <h2 className="f-20 semi-bold mt-3">{title}</h2>
                </div>
                <p className="f-12 m-0">
                    {description}
                </p>
            </div>
        </Link>
    );
};

export default ServiceCard;
