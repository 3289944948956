import React from 'react';

function Terms() {
    return (
        <div>
            <h1>Terms and Conditions</h1>
        </div>
    );
}

export default Terms;
