import React, {useEffect, useState} from 'react';
import {TbSquareRoundedArrowUpFilled} from 'react-icons/tb';

export default function ScrollToTop() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.scrollY > 200) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div>
            {isVisible && (
                <button onClick={scrollToTop} className="ak-round-btn scroll-to-top" style={{maxWidth: 120}}>
                    Top
                    <TbSquareRoundedArrowUpFilled/>
                </button>
            )}
        </div>
    );
}