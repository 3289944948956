import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import img1 from "../assets/images/content/j5.jpg";
import img2 from "../assets/images/content/j4.jpg";
import img3 from "../assets/images/content/j6.jpg";
import "./CascadingCarousel.scss";
import {TbSquareRoundedArrowRightFilled, TbSquareRoundedArrowRight} from "react-icons/tb";
import {Link} from 'react-router-dom';

const CascadingCarousel = () => {
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "40px",
        slidesToShow: 1,
        speed: 500,
        dots: true,
        swipe: true
    };

    return (
        <div
            className="slider-container"
        >
            <div className={"header-slider-container"}>
                <Slider {...settings}>
                    <div className={"carousel-card"}>
                        <img src={img3} alt="Commercial Law" className={"carousel-img"}/>
                        <h3>Immigration Law</h3>
                        <Link to="/services#commercial" className="learn-more-btn">
                            <TbSquareRoundedArrowRightFilled/>
                        </Link>
                        <div className="overlay">
                            <Link to="/services#commercial" className="overlay-button">Learn
                                More <TbSquareRoundedArrowRight/></Link>
                        </div>
                    </div>
                    <div className={"carousel-card"}>
                        <img src={img2} alt="Immigration Law" className={"carousel-img"}/>
                        <h3>Commercial Law</h3>
                        <Link to="/services#immigration" className="learn-more-btn">
                            <TbSquareRoundedArrowRightFilled/>
                        </Link>
                        <div className="overlay">
                            <Link to="/services#immigration" className="overlay-button">Learn
                                More <TbSquareRoundedArrowRight/></Link>
                        </div>
                    </div>
                    <div className={"carousel-card"}>
                        <img src={img1} alt="Real Estate Law" className={"carousel-img"}/>
                        <h3 className={"ak-primary"}>Real Estate Law</h3>
                        <Link to="/services#residential" className="learn-more-btn">
                            <TbSquareRoundedArrowRightFilled/>
                        </Link>
                        <div className="overlay">
                            <Link to="/services#residential" className="overlay-button">Learn
                                More <TbSquareRoundedArrowRight/></Link>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    );
};

export default CascadingCarousel;
