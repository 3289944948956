import React from 'react';
import { BiSolidPaperPlane } from "react-icons/bi";
import { FaBalanceScale } from "react-icons/fa";
import { FaPeopleRoof } from "react-icons/fa6";
import { TbBuildingEstate, TbSquareRoundedArrowRightFilled } from "react-icons/tb";
import { Link } from "react-router-dom";
import { Element, scroller } from 'react-scroll';
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import { useInView } from 'react-intersection-observer';
import './Home.scss';
import { ReactTyped } from 'react-typed';
import highlightExpert from '../assets/images/content/highlight-expert.svg';
import highlightHonest from '../assets/images/content/highlight-honest.svg';
import bannerBg from '../assets/images/content/j1.jpg';
import CascadingCarousel from "../components/CascadingCarousel";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import ServiceCard from "../components/ServiceCard";

function Home() {
    const scrollToBannerSection = () => {
        scroller.scrollTo('home-banner', {
            duration: 600,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -70,
        });
    };

    const scrollToContactSection = () => {
        scroller.scrollTo('home-contact', {
            duration: 600,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -70,
        });
    };

    const { ref: bannerRef, inView: bannerInView } = useInView({ triggerOnce: true, threshold: 0.2 });
    const { ref: contactRef, inView: contactInView } = useInView({ triggerOnce: true, threshold: 0.2 });
    const { ref: aboutRef, inView: aboutInView } = useInView({ triggerOnce: true, threshold: 0.2 });
    const { ref: servicesRef, inView: servicesInView } = useInView({ triggerOnce: true, threshold: 0.2 });
    const { ref: mottoRef, inView: mottoInView } = useInView({ triggerOnce: true, threshold: 0.2 });

    return (
        <ParallaxProvider>
            <div className="home-hero ak-primary-bg">
                <h2 className="semi-bold f-15 ak-white home-welcome-h2">
                    WELCOME TO ARKHAM LAW
                </h2>
                <div className="highlight-wrapper">
                    <img src={highlightHonest} alt="Highlight" className="highlight-honest-image" />
                    <h1 className="semi-bold f-55 ak-white hero-title-h1 highlight-honest-text">
                        Honest Legal
                    </h1>
                </div>
                <h1 className="semi-bold f-55 ak-white hero-title-h1 highlight-honest-text">Representation</h1>
                <div className="mt-3 ak-white hero-typer">
                    Providing expert guidance in{' '}
                    <ReactTyped
                        strings={['immigration.', 'real estate.', 'commercial law.']}
                        typeSpeed={40}
                        backSpeed={50}
                        loop
                    />
                </div>
                <div className="scroll-downs" onClick={scrollToBannerSection}>
                    <div className="mousey">
                        <div className="scroller"></div>
                    </div>
                </div>
            </div>

            <ParallaxBanner
                className="home-parallax-banner"
                layers={[{ image: bannerBg, speed: -15 }]}
                style={{
                    height: '650px',
                    maxHeight: '650px',
                    backgroundPosition: 'top',
                    backgroundSize: 'cover',
                }}
            >
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1,
                }} />
                <div ref={bannerRef} className={`banner-content fade-in-section ${bannerInView ? 'animate-fade-up' : ''}`}>
                    <Element name="home-banner">
                        <div className="text-start banner-left">
                            <h2 className="semi-bold f-15 ak-white home-welcome-h2">OUR SERVICES</h2>
                            <div className="highlight-wrapper">
                                <img src={highlightExpert} alt="Highlight" className="highlight-expert-image" />
                                <h1 className="semi-bold f-55 ak-white hero-title-h1 highlight-expert-text">
                                    Expert Counsel<br />Focused on You
                                </h1>
                            </div>
                            <p className="f-18 ak-white" style={{ marginTop: 30 }}>
                                We offer a comprehensive range of legal services, designed to provide personalized solutions for your unique challenges.
                            </p>
                            <button className="ak-round-btn" onClick={scrollToContactSection}>
                                Contact Us <BiSolidPaperPlane />
                            </button>
                        </div>
                        <div className="banner-right">
                            <CascadingCarousel />
                        </div>
                    </Element>
                </div>
            </ParallaxBanner>

            <div ref={contactRef} className={`home-contact fade-in-section ${contactInView ? 'animate-fade-up' : ''}`}>
                <Element name="home-contact">
                    <h2 className="semi-bold f-15 ak-blue home-welcome-h2">GET IN TOUCH</h2>
                    <h1 className="semi-bold f-35 ak-primary hero-title-h1 highlight-expert-text">Professional & Personable</h1>
                    <Contact />
                </Element>
            </div>

            <div ref={aboutRef} className={`home-about fade-in-section ${aboutInView ? 'animate-fade-up' : ''}`}>
                <Element name="home-about">
                    <div className="home-services-card mx-auto">
                        <div className="row">
                            <div className="col-sm-12 col-md-6 mb-4">
                                <h2 className="semi-bold f-15 ak-blue text-start">ABOUT US</h2>
                                <h1 className="semi-bold f-35 ak-primary hero-title-h1 highlight-expert-text text-start">
                                    Your Trusted<br />Legal Partner
                                </h1>
                            </div>
                            <div className="col-sm-12 col-md-6 text-start">
                                <p>
                                    At Arkham Law, we offer exceptional legal services in immigration, residential, and commercial real estate law. Our experienced attorneys provide personalized guidance, tailoring our approach to meet your unique needs and goals.
                                </p>
                                <p>
                                    Committed to integrity and excellence, we deliver results-driven representation. Whether you're navigating immigration processes, real estate transactions, or commercial matters, we offer comprehensive solutions to help you succeed.
                                </p>
                                <Link to="/about" className="ak-round-btn" style={{ maxWidth: 170 }}>
                                    Learn More <TbSquareRoundedArrowRightFilled />
                                </Link>
                            </div>
                        </div>
                    </div>
                </Element>
            </div>

            <div ref={servicesRef} className={`home-services fade-in-section ${servicesInView ? 'animate-fade-up' : ''}`}>
                <Element name="home-services">
                    <div className="practice-card shadow-sm mx-auto">
                        <div className="mb-4">
                            <h2 className="semi-bold f-15 ak-blue">WHAT WE DO</h2>
                            <h1 className="semi-bold f-35 ak-primary hero-title-h1 highlight-expert-text">
                                Our Primary<br />Practice Areas
                            </h1>
                            <p className="f-16 ak-gray mt-3">
                                Explore our range of legal expertise tailored to meet diverse client needs.<br />
                                We are dedicated to providing exceptional guidance across key practice areas.
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-4 text-start">
                                <ServiceCard
                                    icon={<FaPeopleRoof size={50} />}
                                    title="Immigration Law"
                                    description="Expert guidance through complex immigration processes for individuals and businesses."
                                    serviceId="immigration"
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 text-start">
                                <ServiceCard
                                    icon={<TbBuildingEstate size={50} />}
                                    title="Real Estate Law"
                                    description="Comprehensive assistance with property transactions and real estate matters."
                                    serviceId="residential"
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 text-start">
                                <ServiceCard
                                    icon={<FaBalanceScale size={50} />}
                                    title="Commercial Law"
                                    description="Providing strategic legal support for complex commercial property transactions"
                                    serviceId="commercial"
                                />
                            </div>
                        </div>
                    </div>
                </Element>
            </div>

            <div ref={mottoRef} className={`home-motto fade-in-section ${mottoInView ? 'animate-fade-up' : ''}`}>
                <Element name="home-motto">
                    <div className="home-motto-card mx-auto">
                        <div className="row">
                            <div className="col-sm-12 mb-4">
                                <h2 className="semi-bold f-15 ak-blue text-start">OUR MOTTO AT THE FIRM</h2>
                                <h1 className="semi-bold f-35 ak-primary hero-title-h1 highlight-expert-text text-start">
                                    We're always curious about making legal matters better. We avoid shortcuts and standard solutions, opting for what's right, not just what's easy.
                                </h1>
                                <Link to="/contact" className="ak-round-btn" style={{ maxWidth: 170 }}>
                                    Get in Touch <BiSolidPaperPlane />
                                </Link>
                            </div>
                        </div>
                    </div>
                </Element>
            </div>

            <Element name="home-footer" className="home-footer">
                <Footer />
            </Element>
        </ParallaxProvider>
    );
}

export default Home;
