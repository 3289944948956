import React, { useEffect, useState } from "react";
import "./Contact.scss";
import blueGuy from '../assets/images/content/h3.png';
import { RiSendPlane2Fill } from "react-icons/ri";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Contact() {
    const [result, setResult] = useState("");

    useEffect(() => {}, []);

    const onSubmit = async (event) => {
        event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);

        formData.append("access_key", process.env.REACT_APP_W3FORMS_ACCESS_KEY);

        const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
        });

        const data = await response.json();

        if (data.success) {
            toast.success("Thanks for your message! We'll be in touch soon!", {
                position: "top-center",
            });
            event.target.reset();
        } else {
            console.log("Error", data);
            toast.error(data.message);
        }
    };

    return (
        <>
            <div
                className={`contact-card shadow-sm animate-fade-up'}`}
            >
                <div className="row g-0">
                    <div className="col-md-6 image-column">
                        <img src={blueGuy} alt="Illustration" className="img-fluid" />
                    </div>
                    <div className="col-md-6 form-column">
                        <h3 className="form-subheader">We're Here to Help</h3>
                        <p className="form-description">Please fill out the form below, and we’ll get back to you shortly.</p>
                        <form onSubmit={onSubmit}>
                            <input type="text" name="name" required placeholder="Your Name" className="form-control mb-3" />
                            <input type="email" name="email" required placeholder="Your Email" className="form-control mb-3" />
                            <input type="tel" name="phone" placeholder="Your Phone" className="form-control mb-3" />
                            <textarea name="message" required placeholder="Your Message" className="form-control mb-3"></textarea>
                            <button className="ak-round-white-btn" type="submit">
                                Submit
                                <RiSendPlane2Fill />
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}
