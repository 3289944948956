import React from 'react';
import "./Footer.scss"
import {BiSolidPaperPlane} from "react-icons/bi";
import {Link} from "react-router-dom";
import highlightExpert from "../assets/images/content/highlight-expert.svg";
import logoStacked from "../assets/images/logos/logo_stacked_white.svg"
import logoSvg from "../assets/images/logos/logo_only_secondary.svg"
import {HiMiniArrowSmallRight} from "react-icons/hi2";

import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const Footer = () => {

    // 111 28 Ave NW, Calgary, AB T2M 2K3
    const position = [51.077620, -114.063350];

    return (
        <div className={"footer-container"}>
            <img alt={"Footer Logo"} src={logoSvg} className={"footer-svg"}/>
            <div className="footer-card container-fluid">

                <div className={"row"}>
                    <div className={"col-sm-12 col-md-6"}>
                        <MapContainer attributionControl={false} center={position} zoom={13} className={"footer-map"}>
                            <TileLayer
                                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                            />
                            <Marker position={position}>
                                <Popup>
                                    Location: 111 28 Ave NW, Calgary, AB T2M 2K3
                                </Popup>
                            </Marker>
                        </MapContainer></div>
                    <div className="col-sm-12 col-md-6 text-start">
                        <div className="text-start mb-4">
                            <h2 className="semi-bold f-15 ak-white">
                                GET IN TOUCH
                            </h2>
                            <div className="highlight-wrapper">
                                <img
                                    src={highlightExpert}
                                    alt="Highlight"
                                    className="highlight-footer-image"
                                />
                                <h1 className="semi-bold f-25 ak-white highlight-footer-text">
                                    Have a question?<br/>Here’s how to find us.
                                </h1>
                            </div>
                        </div>
                        <div className="row g-3">
                            <div className="col-6 f-12">
                                <h6>MESSAGE US</h6>
                                <p>reception@arkhamlaw.com</p>
                            </div>
                            <div className="col-6 f-12">
                                <h6>PHONE</h6>
                                <p>(403) 930-7788</p>
                            </div>
                            <div className="col-6 f-12">
                                <h6>ADDRESS</h6>
                                <p>111 28 Ave NW, Calgary, AB T2M 2K3</p>
                            </div>
                            <div className="col-6 f-12">
                                <h6>BUSINESS HOURS</h6>
                                <p>Mon - Fri 9:00 am - 4:30 pm<br/>Sat - Sun Closed</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 text-start mb-4 mb-md-0">
                        <div className="footer-logo-stacked-container">
                            <img src={logoStacked} alt="LogoStacked" className="footer-logo-stacked"/>
                            <p className="mt-3">
                                Your trusted Calgary firm for real estate, immigration, and commercial law services.
                            </p>
                        </div>
                    </div>

                    <div className="col-12 col-md-3 text-start mb-4 mb-md-0">
                        <h4 className="footer-label">Services</h4>
                        <ul className="list-unstyled">
                            <li>
                                <Link to="/services#immigration" className="footer-link">
                                    Immigration Law <HiMiniArrowSmallRight className="link-icon"/>
                                </Link>
                            </li>
                            <li>
                                <Link to="/services#residential" className="footer-link">
                                    Real Estate Law <HiMiniArrowSmallRight className="link-icon"/>
                                </Link>
                            </li>
                            <li>
                                <Link to="/services#commercial" className="footer-link">
                                    Commercial Law <HiMiniArrowSmallRight className="link-icon"/>
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col-12 col-md-3 text-start">
                        <h4 className="footer-label">Support</h4>
                        <ul className="list-unstyled">
                            <li>
                                <Link to="/about" className="footer-link">
                                    About Us <HiMiniArrowSmallRight className="link-icon"/>
                                </Link>
                            </li>
                            {/*<li>*/}
                            {/*    <Link to="/services" className="footer-link">*/}
                            {/*        FAQs <HiMiniArrowSmallRight className="link-icon"/>*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <Link to="/privacy-policy" className="footer-link">*/}
                            {/*        Privacy Policy <HiMiniArrowSmallRight className="link-icon"/>*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <Link to="/terms" className="footer-link">*/}
                            {/*        Terms & Conditions <HiMiniArrowSmallRight className="link-icon"/>*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            <li>
                                <Link to="/contact" className="footer-link">
                                    Contact Us <HiMiniArrowSmallRight className="link-icon"/>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="footer-border"></div>
            <div className="copyright">
                © 2024 Arkham Law
            </div>
        </div>
    );
};

export default Footer;
