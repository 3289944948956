import React, { useEffect } from 'react';
import { BiSolidPaperPlane } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { Element, scroller } from 'react-scroll';
import { useInView } from 'react-intersection-observer';
import './Home.scss';
import highlightHonest from '../assets/images/content/highlight-honest.svg';
import Footer from "../components/Footer";
import s1 from '../assets/images/content/s1.png';
import s2 from '../assets/images/content/s2.png';
import s3 from '../assets/images/content/s3.png';
import ServiceCommitment from "../components/ServiceCommitment";

function Services() {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const hash = location.hash.replace('#', '');
            const element = document.getElementById(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                window.scrollBy(0, -80);
            }
        }
    }, [location]);

    const scrollToBannerSection = () => {
        scroller.scrollTo('services-commitment', {
            duration: 600,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -70,
        });
    };

    // Set up inView triggers for each section
    const { ref: bannerRef, inView: bannerInView } = useInView({ triggerOnce: true, threshold: 0.2 });
    const { ref: commitmentRef, inView: commitmentInView } = useInView({ triggerOnce: true, threshold: 0.2 });
    const { ref: residentialRef, inView: residentialInView } = useInView({ triggerOnce: true, threshold: 0.2 });
    const { ref: commercialRef, inView: commercialInView } = useInView({ triggerOnce: true, threshold: 0.2 });
    const { ref: immigrationRef, inView: immigrationInView } = useInView({ triggerOnce: true, threshold: 0.2 });

    return (
        <div>
            {/* Banner Section */}
            <div ref={bannerRef} className={`home-hero ak-primary-bg fade-in-section ${bannerInView ? 'animate-fade-up' : ''}`}>
                <h2 className="semi-bold f-15 ak-white home-welcome-h2">OUR PRACTICE AREAS</h2>
                <div className="highlight-wrapper">
                    <img src={highlightHonest} alt="Highlight" className="highlight-guiding-image" />
                    <h1 className="semi-bold f-55 ak-white hero-title-h1 highlight-honest-text">
                        Guiding You<br />The Legal Way
                    </h1>
                </div>
                <div className="scroll-downs" onClick={scrollToBannerSection}>
                    <div className="mousey">
                        <div className="scroller"></div>
                    </div>
                </div>
            </div>

            {/* Commitment Section */}
            <div ref={commitmentRef} className={`home-contact fade-in-section ${commitmentInView ? 'animate-fade-up' : ''}`}>
                <Element name="services-commitment">
                    <h2 className="semi-bold f-15 ak-blue home-welcome-h2">SERVICES</h2>
                    <h1 className="semi-bold f-35 ak-primary hero-title-h1 highlight-expert-text">Our Commitment</h1>
                    <div className="mt-3" style={{ maxWidth: 500, padding: '10px 15px', margin: '0 auto' }}>
                        <p>
                            We’re dedicated to delivering exceptional support in immigration, residential, and commercial real estate. We believe in building lasting partnerships through integrity and personalized attention, ensuring each client receives the expertise and care needed to achieve their goals confidently.
                        </p>
                    </div>
                </Element>
            </div>

            {/* Service Commitments */}
            <div className="home-services-card mx-auto">
                <Element name="home-story">
                    {/* Real Estate Law Section */}
                    <div ref={residentialRef} className={`fade-in-section ${residentialInView ? 'animate-fade-up' : ''}`}>
                        <Element id="residential">
                            <ServiceCommitment
                                id="01"
                                name="Real Estate Law"
                                description="Our Real Estate Law services offer you security and confidence through every property transaction."
                                fullDescription="Our Real Estate Law services guide you through each step of property transactions with expertise and dedication. From residential purchases to complex land development, we ensure your property rights are protected. Our attorneys work closely with clients to navigate zoning laws, contract negotiations, and property closings, safeguarding your interests and investment for a secure future."
                                image={s1}
                                sectionId="residential"
                            />
                        </Element>
                    </div>

                    {/* Commercial Real Estate Law Section */}
                    <div ref={commercialRef} className={`fade-in-section ${commercialInView ? 'animate-fade-up' : ''}`}>
                        <Element id="commercial">
                            <ServiceCommitment
                                id="02"
                                name="Commercial Real Estate Law"
                                description="With a focus on business growth, our Commercial Real Estate Law services simplify your property investments."
                                fullDescription="Our Commercial Real Estate Law services offer comprehensive support for businesses, covering acquisitions, leases, and property management. Whether you are purchasing a storefront, leasing office space, or handling multi-property portfolios, we bring legal insight to protect your interests. Our team manages regulatory compliance, contract drafting, and negotiations, ensuring smooth transactions that align with your business goals."
                                image={s2}
                                sectionId="commercial"
                            />
                        </Element>
                    </div>

                    {/* Immigration Law Section */}
                    <div ref={immigrationRef} className={`fade-in-section ${immigrationInView ? 'animate-fade-up' : ''}`}>
                        <Element id="immigration">
                            <ServiceCommitment
                                id="03"
                                name="Immigration Law"
                                description="Our Immigration Law team is committed to helping individuals and businesses navigate the path to successful immigration."
                                fullDescription="Our Immigration Law services offer personalized guidance through the complexities of visa, residency, and citizenship applications. Whether you're an individual seeking family reunification or a business hiring international talent, we provide tailored legal solutions. Our attorneys offer expertise in managing complex applications, appeals, and compliance, providing you the clarity and support needed to achieve your immigration goals."
                                image={s3}
                                sectionId="immigration"
                            />
                        </Element>
                    </div>
                </Element>
            </div>

            <Footer />
        </div>
    );
}

export default Services;
