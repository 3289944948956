import React, {useEffect} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes, useLocation} from 'react-router-dom';
import Header from './components/Header';
import About from './pages/About';
import ContactPage from "./pages/ContactPage";
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Services from './pages/Services';
import Terms from './pages/Terms';
import './App.scss';
import ScrollToTop from "./components/ScrollToTop.js"

function App() {
    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.replace("#", ""));
            if (element) {
                element.scrollIntoView({behavior: "smooth", block: "start"});
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return (
        <div className="App">
            <Header/>
            <div className="app-container">
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/home" element={<Home/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/services" element={<Services/>}/>
                    <Route path="/contact" element={<ContactPage/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="/terms" element={<Terms/>}/>
                    <Route path="*" element={<Navigate to="/home"/>}/>
                </Routes>
            </div>
            <ScrollToTop/>
        </div>
    );
}

function AppWithRouter() {
    return (
        <Router>
            <App/>
        </Router>
    );
}

export default AppWithRouter;