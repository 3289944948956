import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logos/logo_only_primary.svg';
import './Header.scss';
import { RiArrowRightDoubleLine } from "react-icons/ri";

function Header() {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', onScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    return (
        <header className={isScrolled ? 'header scrolled' : 'header'}>
            <nav className={`navbar navbar-expand-md ${isScrolled ? 'navbar-scrolled' : ''}`}>
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="Logo" className="navbar-logo" />
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            {/* Your nav items */}
                            <li className="nav-item">
                                <Link className="nav-link" to="/">
                                    Home
                                </Link>

                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/about">
                                    About
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/services">
                                    Services
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link d-none d-md-block ak-primary-bg ak-white px-3 py-2 contact-link"
                                    to="/contact"
                                >
                                    Contact
                                    <span className="icon-wrapper">
                                        <RiArrowRightDoubleLine/>
                                    </span>
                                </Link>

                                <Link className="nav-link d-block d-md-none" to="/contact">
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;
