import React from 'react';
import { BiSolidPaperPlane } from "react-icons/bi";
import { Element } from 'react-scroll';
import { useInView } from 'react-intersection-observer';
import './Home.scss';
import highlightHonest from '../assets/images/content/highlight-honest.svg';
import Contact from "../components/Contact";
import FooterMinimal from "../components/FooterMinimal";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function ContactPage() {
    const position = [51.077620, -114.063350];

    // Using Intersection Observers
    const { ref: contactRef, inView: contactInView } = useInView({ triggerOnce: true, threshold: 0.1 });
    const { ref: mottoRef, inView: mottoInView } = useInView({ triggerOnce: true, threshold: 0.1 });
    const { ref: mapRef, inView: mapInView } = useInView({ triggerOnce: true, threshold: 0.1 });

    return (
        <div>
            {/* Contact Section */}
            <div ref={contactRef} className={`home-contact fade-in-section ${contactInView ? 'animate-fade-up' : ''}`}>
                <Element name="home-contact">
                    <h2 className="semi-bold f-15 ak-blue home-welcome-h2">GET IN TOUCH</h2>
                    <h1 className="semi-bold f-35 ak-primary hero-title-h1 highlight-expert-text">Professional & Personable</h1>
                    <Contact />
                </Element>
            </div>

            {/* Motto Section */}
            <div ref={mottoRef} className={`home-motto fade-in-section ${mottoInView ? 'animate-fade-up' : ''}`}>
                <Element name="home-motto">
                    <div className="home-motto-card mx-auto" style={{ marginTop: 0 }}>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 text-start mb-3">
                                <div className="text-start mb-4">
                                    <div className="highlight-wrapper">
                                        <h1 className="semi-bold f-25 ak-primary highlight-footer-text">
                                            Have a question?<br />Here’s how to find us.
                                        </h1>
                                    </div>
                                </div>
                                <div className="row g-3">
                                    <div className="col-12 col-sm-6 col-md-3 f-12">
                                        <h6>MESSAGE US</h6>
                                        <p>reception@arkhamlaw.com</p>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-3 f-12">
                                        <h6>PHONE</h6>
                                        <p>(403) 930-7788</p>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-3 f-12">
                                        <h6>ADDRESS</h6>
                                        <p>111 28 Ave NW, Calgary, AB T2M 2K3</p>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-3 f-12">
                                        <h6>BUSINESS HOURS</h6>
                                        <p>Mon - Fri 9:00 am - 4:30 pm<br />Sat - Sun Closed</p>
                                    </div>
                                </div>
                            </div>
                            <div className={`col-sm-12 col-md-12 fade-in-section ${mapInView ? 'animate-fade-up' : ''}`} ref={mapRef} style={{ opacity: mapInView ? 1 : 0, transition: 'opacity 0.6s ease-out' }}>
                                <MapContainer attributionControl={false} center={position} zoom={12} className="footer-map">
                                    <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
                                    <Marker position={position}>
                                        <Popup>
                                            Location: 111 28 Ave NW, Calgary, AB T2M 2K3
                                        </Popup>
                                    </Marker>
                                </MapContainer>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 mb-4">
                                <h2 className="semi-bold f-15 ak-blue text-start">YOUR NEEDS, OUR PRIORITY</h2>
                                <h1 className="semi-bold f-35 ak-primary hero-title-h1 highlight-expert-text text-start">
                                    We listen to your concerns and provide guidance with integrity, ensuring you feel supported every step of the way.
                                </h1>
                            </div>
                        </div>
                    </div>
                </Element>
            </div>

            {/* Footer Section */}
            <Element name="home-footer" className="home-footer">
                <FooterMinimal />
            </Element>
        </div>
    );
}

export default ContactPage;
