import React from 'react';

function PrivacyPolicy() {
    return (
        <div>
            <h1>Privacy Policy</h1>
        </div>
    );
}

export default PrivacyPolicy;
